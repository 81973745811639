<template>
  <div class="query-operator">
    <div class="query-left">
      <slot name="left"></slot>
    </div>
    <div class="query-right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less">
.query-operator {
  display: flex;
  padding: 10px 0;
  justify-content: space-between;
  .query-left,
  .query-right {
    flex: 0 0 auto;
  }
  .query-action {
    width: 150px;
    vertical-align: top;
  }
  .query-action {
    margin-right: 8px;
  }
  .ant-btn {
    vertical-align: top;
  }
}
</style>
