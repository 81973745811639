import {
  isObject
} from '@/utils/util'

export default {
  props: {
    width: {
      type: Number,
      default: 700
    },
    fullscreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      drawerWidth: this.fullscreen ? '100%' : this.width,
      title: '',
      visible: false,
      disableSubmit: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      confirmLoading: false,
      formData: {},
      isAdd: false,
      addMessage: '新增成功',
      editMessage: '保存成功'
    }
  },
  methods: {
    handleAdd(record, title) { // 添加
      if (title) { // 设置title
        this.title = title || '新增'
      }
      this.$refs.form && this.$refs.form.clearValidate()
      this.setModel('beforeAdd', record || {})
      this.isAdd = true
      this.getInitData && this.getInitData(record) // 获取初始化数据
      this.visible = true
    },
    handleEdit(record, title) { // 修改
      if (title) { // 设置title
        this.title = title || '修改'
      }
      this.setModel('beforeEdit', record || {})
      this.isAdd = false
      this.getInitData && this.getInitData(record) // 获取初始化数据
      this.visible = true
    },
    handleOk () {
      this.confirmLoading = true
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.handleSave) {
            this.handleSave()
              .then(() => {
                this.$message.info(this.isAdd ? this.addMessage : this.editMessage)
                this.visible = false
                this.resetForm()
                this.$emit('save')
              })
              .catch(e => {
                console.log(e)
              })
              .finally(() => {
                this.confirmLoading = false
              })
          } else {
            this.confirmLoading = false
          }
        } else {
          this.confirmLoading = false
        }
      })
    },
    resetForm() {
      Object.keys(this.formData).forEach(key => {
        if (Array.isArray(this.formData[key])) {
          this.formData[key] = []
        } else {
          this.formData[key] = undefined
        }
      })
      if (this.afterResetForm) { // 重置表单后调用
        this.afterResetForm()
      }
    },
    setModel (beforFuncName, record) {
      let result
      if (this[beforFuncName]) {
        result = this[beforFuncName](record) // 需返回对象
      }
      if (result) {
        if (result instanceof Promise) { // 如果是promise 则去.then的值
          this.confirmLoading = true
          result.then(data => {
            console.log(data)
            this.setFieldsValue({ ...record, ...data })
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.setFieldsValue({ ...record, ...result })
        }
      } else {
        this.setFieldsValue({ ...record })
      }
    },
    setFieldsValue (data) {
      if (data && isObject(data)) {
        Object.keys(this.formData).forEach(key => {
          if (data[key] !== undefined) {
            this.formData[key] = data[key]
          }
        })
        this.formData = { ...this.formData }
      }
    },
    handleCancel() {
      this.$emit('close')
      this.disableSubmit = false
      this.visible = false
      this.resetForm()
    }
  }
}