<template>
  <a-drawer
    :title="title"
    wrapClassName="form-edit-drawer"
    placement="right"
    :visible="visible"
    :width="drawerWidth"
    :destroyOnClose="false"
    :body-style="{ paddingBottom: '80px' }"
    @close="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form-model :model="formData" :rules="validatorRules" :labelCol="labelCol" :wrapperCol="wrapperCol" ref="form">
        <a-form-model-item prop="type" label="招聘版块">
          <a-select v-model="formData.type" placeholder="请输入招聘版块" :options="jobTypes" />
        </a-form-model-item>
        <a-form-model-item prop="name" label="岗位名称">
          <a-input v-model="formData.name" placeholder="请输入岗位名称" />
        </a-form-model-item>
        <a-form-model-item prop="duty" label="工作职责">
          <a-textarea :rows="4" v-model="formData.duty" placeholder="请输入工作职责" />
        </a-form-model-item>
        <a-form-model-item prop="content" label="任职要求">
          <a-textarea :rows="4" v-model="formData.content" placeholder="请输入任职要求" />
        </a-form-model-item>
      </a-form-model>
    </a-spin>
    <div
      class="drawer-button"
    >
      <a-button style="margin-right: 8px;" @click="handleCancel">
        取消
      </a-button>
      <a-button type="primary" @click="handleOk">
        保存
      </a-button>
    </div>
  </a-drawer>
</template>

<script>
import editFormModelMixin from '@/mixins/editFormModelMixin'
import {
  saveJob
} from '@/api'
import { filterObj } from '@/utils/util'
export default {
  mixins: [editFormModelMixin],
  props: {
    jobTypes: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      formData: {
        id: '',
        type: undefined,
        name: '',
        duty: '',
        content: ''
      }
    }
  },
  computed: {
    validatorRules () {
      return {
        type: [
          {
            required: true,
            message: '请输入招聘板块'
          }
        ],
        name: [
          {
            required: true,
            message: '请输入招聘岗位'
          }
        ],
        duty: [
          {
            required: true,
            message: '请输入工作职责'
          }
        ],
        content: [
          {
            required: true,
            message: '请输入工作内容'
          }
        ]
      }
    }
  },
  methods: {
    handleSave () { // 新增或修改
      const formData = filterObj(this.formData)
      formData.jobId = formData.id || 'add'
      return saveJob(formData)
    }
  }
}
</script>

