<template>
  <div class="recruit-center-page">
    <div class="page-title">招聘中心列表</div>
    <QueryOperator>
      <template slot="left">
        <a-button icon="plus" type="primary" @click="addRecruit">新建</a-button>
      </template>
      <template slot="right">
        <a-select @change="searchData" v-model="queryParam.type" allow-clear class="query-action" placeholder="板块" :options="jobTypes" />
        <a-button type="primary" @click="searchData">查询</a-button>
      </template>
    </QueryOperator>
    <a-card>
      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="dataSource"
        :pagination="ipagination"
        @change="handleTableChange"
        rowKey="id"
      >
        <span slot="type" slot-scope="type, record">
          {{ jobTypeHash[type] }}
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="editRecruit(record)">编辑</a>
          <a-divider type="vertical" />
          <a-popconfirm title="确定删除吗？" @confirm="() => delRecruit(record)">
            <a class="error">删除</a>
          </a-popconfirm>
          <a-divider type="vertical" />
          <a @click="setRecruitTop(record)">{{ record.weight ? '取消置顶' : '置顶' }}</a>
        </span>
      </a-table>
    </a-card>
    <RecruitEdit :jobTypes="jobTypes" @save="getJobsList" ref="recruitEdit" />
  </div>
</template>

<script>
import moment from 'moment'
import QueryOperator from '@/components/Operator/QueryOperator'
import RecruitEdit from './components/recruitEdit'
import { 
  getJobsList,
  getJobType,
  deleteJob,
  setJobTop
} from '@/api'
import {
  filterObj
} from '@/utils/util'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id'
  },
  {
    title: '所属版块',
    dataIndex: 'type',
    scopedSlots: { customRender: 'type' }
  },
  {
    title: '岗位',
    dataIndex: 'name'
  },
  {
    title: '工作职责',
    dataIndex: 'duty',
    ellipsis: true
  },
  {
    title: '任职要求',
    dataIndex: 'content',
    ellipsis: true
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  },
]
export default {
  components: {
    QueryOperator,
    RecruitEdit
  },
  data () {
    return {
      loading: false,
      // 查询参数
      queryParam: {
        type: undefined
      },
      columns,
      dataSource: [],
      // 分页
      ipagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      jobTypes: [],
      jobTypeHash: {}
    }
  },
  created () {
    this.getJobsList()
    this.getJobType()
  },
  methods: {
    // 查询数据
    searchData () {
      this.ipagination.current = 1
      // 获取新闻列表
      this.getJobsList()
    },
    handleSearch () {
      this.getJobsList()
    },
    getJobType () {
      return getJobType()
        .then(({data}) => {
          this.jobTypeHash = {}
          this.jobTypes = data.map(item => {
            this.jobTypeHash[item.id] = item.name
            return { label: item.name, value: item.id }
          })
        })
        .catch((e) => {
          this.jobTypes = []
        })
    },
    // 分页选择
    handleTableChange (pagination) {
      this.ipagination = { ...pagination }
      // 获取新闻列表
      this.getNewsList()
    },
    getJobsList () {
      const { current, pageSize } = this.ipagination
      return getJobsList(filterObj({
        page: current,
        size: pageSize,
        ...this.queryParam
      }))
        .then(({ data }) => {
          this.dataSource = data.list
          this.ipagination.total = data.total
        })
        .catch(() => {
          this.dataSource = []
          this.ipagination.total = 0
        })
    },
    setRecruitTop (record) {
      return setJobTop({ jobId: record.id, id: record.id, action: record.weight ? 0 : 1 })
        .then(() => {
          this.$message.info('设置成功')
          this.getJobsList()
        })
    },
    delRecruit (record) {
      return deleteJob({ jobId: record.id })
        .then(() => {
          this.getJobsList()
        })
    },
    editRecruit (record) { // 编辑添加招聘岗位
      this.$refs.recruitEdit.handleEdit(record, '编辑添加招聘岗位')
    },
    addRecruit () { // 添加招聘岗位
      this.$refs.recruitEdit.handleAdd({}, '添加招聘岗位')
    }
  }
}
</script>

